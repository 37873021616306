<template>
	<nav class="navbar">
		<q-toolbar class="bg-white text-black">
			<q-avatar>
				<img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-black.svg" />
			</q-avatar>
			<q-space />
			<!-- <q-tabs v-model="tab" shrink> -->
			<q-tabs indicator-color="negative" shrink>
				<q-route-tab name="tab1" label="Galeria" to="/" />
				<q-route-tab name="tab2" label="Kontak" to="/" />
			</q-tabs>
		</q-toolbar>
	</nav>
</template>

<script>
export default {
	name: "NavBar",
};
</script>


<style lang="scss" scoped>
</style>