<template>
	<div class="wip">
		<div class="wip__top">
			<div class="wip__top__wrapper">
				<div class="wip__top__wrapper__logo">
					<img src="../assets/logo.png" />
				</div>
				<div class="wip__top__wrapper__info">
					<!-- <div class="text-h2 text-center construction">STRONA W BUDOWIE</div> -->
					<div class="text-h2 text-center construction"></div>
				</div>
				<div class="wip__top__wrapper__download">
					<q-card class="my-card">
						<q-card-section>
							<div class="text-h6 text-center">600-480-024</div>
							<div class="text-subtitle2 text-center">
								ZAPRASZAMY DO POBRANIA PROJEKTU I WIZUALIZACJI
							</div> </q-card-section
						><q-separator />
						<q-card-actions vertical>
							<q-btn
								flat
								class="text-bold"
								label="Pobierz materiały"
								@click.prevent="download()"
							/>
						</q-card-actions>
					</q-card>
				</div>
			</div>
		</div>
		<div class="wip__bottom">
			<div class="wip__bottom__wrapper">
				<div class="wip__bottom__wrapper__info">
					<div class="text-h2 text-center construction">STRONA W BUDOWIE</div>
				</div>
				<div class="wip__bottom__wrapper__download">
					<div class="text-h6 text-center">600-480-024</div>
					<div class="text-subtitle2 text-center invite">
						ZAPRASZAMY DO POBRANIA PROJEKTU I WIZUALIZACJI
					</div>
					<q-btn
						class="text-bold"
						label="Pobierz materiały"
						@click.prevent="download()"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			materialsUrl: require("../assets/lesna-brama.zip"),
		};
	},

	methods: {
		download() {
			axios
				.get(this.materialsUrl.default, { responseType: "blob" })
				.then((response) => {
					const blob = new Blob([response.data], { type: "application/zip" });
					const link = document.createElement("a");
					link.href = URL.createObjectURL(blob);
					link.download = "Leśna Brama";
					link.click();
					URL.revokeObjectURL(link.href);
				})
				.catch(console.error);
		},
	},
};
</script>

<style lang="scss" scoped>
.wip {
	&__top {
		background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.05)),
			url("../assets/banner.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		position: relative;
		height: 100vh;
		justify-content: center;

		&__wrapper > div {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			height: 100%;

			&__logo {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;

				img {
					height: 250px;
				}
			}

			&__info {
				height: 180px;

				.construction {
					letter-spacing: 7px;
					color: white;
					margin: 5px 0;
				}
			}

			&__download {
			}
		}
	}

	&__bottom {
		display: none;
	}
}

@media all and (max-width: 540px) {
	.wip {
		&__top {
			height: 48vh;

			&__wrapper {
				&__logo {
					img {
						height: 180px;
					}
				}
				&__info {
					display: none !important;
				}

				&__download {
					display: none !important;
				}
			}
		}

		&__bottom {
			display: block;

			&__wrapper {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				height: 45vh;

				&__info {
					height: 25vh;
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: center;

					.construction {
						font-size: 42px;
						padding: 0 10px;
					}
				}

				&__download {
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					// justify-content: center;
					align-items: center;
					justify-content: space-evenly;
					height: 25vh;

					.invite {
						padding: 0 10px;
					}
				}
			}
		}
	}
}
</style>